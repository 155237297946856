import axios from 'axios'
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.masterDataDomain;

const state = {
  status: false,
  dresses: [],
  productsReport: [],
  filteredDresses: [],
  filteredDressColors: [],
  dressSize: [],
  dressColors: [],
  currentPage: 1,
  colorsCurrentPage: 1,
  totalItems: 0,
  colorsTotalItems: 2,
  allColor: [],
};

const getters = {
  getDresses: (state) => {
    return state.dresses;
  },
  getProductsReport: (state) => {
    return state.productsReport;
  },
  getFilteredDresses: (state) => {
    return state.filteredDresses;
  },
  getFilteredDressColors: (state) => {
    return state.filteredDressColors;
  },
  getDressesSize: (state) => {
    return state.dressSize;
  },
  getCurrentPage: (state) => {
    return state.currentPage;
  },
  getCurrentPageForColors: (state) => {
    return state.colorsCurrentPage;
  },
  getDressColors: (state) => {
    return state.dressColors;
  },
  getAllColors: (state) => {
    return state.allColor
  },
  getTotalItemsForDresses: (state) => {
    return state.totalItems;
  },
  getTotalItemsForDressColors: (state) => {
    return state.colorsTotalItems;
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_DRESSES(state, latest) {
    state.dresses = latest
  },
  RESTART(state, latest) {
    state.dresses = latest;
  },
  SET_PRODUCTS_REPORT(state, latest) {
    state.productsReport = latest;
  },
  SET_FILTERED_DRESSES(state, latest) {
    state.filteredDresses = latest;
  },
  SET_FILTERED_DRESS_COLORS(state, latest) {
    state.filteredDressColors = latest;
  },
  SET_DRESS_SIZE(state, payload) {
    state.dressSize = payload;
  },
  SET_DRESS_COLORS(state, latest) {
    state.dressColors = latest;
  },
  SET_ALL_COLORS(state, latest) {
    state.allColor = latest
  },
  CLEAR_SIZE(state) {
    state.dressSize = [];
  },
  SET_TOTALITEMS_DRESSES(state, latest) {
    state.totalItems = latest;
  },
  SET_DRESS_COLORS_TOTALITEMS(state, latest) {
    state.colorsTotalItems = latest;
  },
  SET_DRESS_COLORS_PAGENUMBER(state, latest) {
    state.colorsCurrentPage = latest;
  },
};


const actions = {
  resetSizes({ commit }) {
    commit('SET_DRESS_SIZE', []);
  },

  async inventoryProductSizeFromColorId({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inventoryProductSizesByMDCColorId?id=${id}`);
      commit('SET_DRESS_SIZE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addInventoryProductSize({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addInventoryProduct`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Inventory product added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid inventory data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },

  async editInventoryProductSize({ commit }, { inventoryProductId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editInventoryProductById?inventoryProductId=${inventoryProductId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Inventory product edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid inventory data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },

  async addMainDressCategory({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object)
    try {
      await axios.post(`${domain}/addMainDressCategory`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    successCallback();
  },
  async editMainDressCategory({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editMainDreeCategoryById?maindDressCategoryId=${object.id}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Main Dress edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid dress data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteMainDress({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deleteMainDressCategoryById?id=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Main dress deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid dress data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteColorDress({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/delete_mmc_ById?id=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Dress color deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid dress data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDressesPagination({ commit }, {
    dressCategoryId, pageNumber, pageSize, mdcName, code,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/mDCsPagination`, {
        params: {
          mdcName,
          pageNumber,
          pageSize,
          dressCategoryId,
          code,
        },
      });
      commit('SET_DRESSES', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_DRESSES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadProductReportingPagination({ commit }, { pageNumber, pageSize, mdcName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/mDCsPagination`, {
        params: {
          mdcName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCTS_REPORT', response.data.items);
      commit('SET_TOTALITEMS_DRESSES', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },

  async addColorToMainDressCategoryById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    // const objToJson = JSON.stringify(object)
    try {
      await axios.post(
        `${domain}/addColorToMainDressCategoryById?id=${object.mainDressCategoryId}`,
        object,
      );
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editColorToMainDressCategoryById({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(
        `${domain}/editMainDressCategoryColor?id=${object.mainDressCategoryColorId}`,
        object,
      );
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Color edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid color data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDressColorsById({ commit }, {
    mainDressCategoryId, name, pageNumber, pageSize,
  }) {
    commit('CLEAR_SIZE');
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getMDCColorsByMainDressCategoryId?mainDressCategoryId=${mainDressCategoryId}`, {
        params: {
          name,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_DRESS_COLORS', response.data.items);
      commit('SET_DRESS_COLORS_TOTALITEMS', response.data.totalItems);
      commit('SET_DRESS_COLORS_PAGENUMBER', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadAllColors({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/mmc_colors`);
      commit('SET_ALL_COLORS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filteredDressColorsByName({ commit }, { mainDressCategoryId, name }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getMDCColorsByMainDress_nameLike`, {
        params: {
          mainDressCategoryId,
          name,
        },
      });
      commit('SET_FILTERED_DRESS_COLORS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filteredDressesByName({ commit }, { code, name }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(
        `${domain}/mainDressCategorieNamesByNameLike`, {
          params: {
            name,
            code,
          },
        },
      );
      commit('SET_FILTERED_DRESSES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredDresses({ commit }) {
    commit('SET_FILTERED_DRESSES', []);
  },
  resetFilteredDressColors({ commit }) {
    commit('SET_FILTERED_DRESS_COLORS', []);
  },
  resetDressColors({ commit }) {
    commit('SET_DRESS_COLORS', []);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
